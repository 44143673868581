/**
 * File: createTicket.scss
 * Description: This file contains the css of the ticket creation page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

// Ticket creation steps
.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  border-right: 2px solid #e0dfdf;
}

.step-line {
  background-color: #024b77;
  width: 1px;
  height: 90px;
  margin: 8px 0px;
}

// Ticket creation body
.ticket-creation-body {
  padding: 16px 48px;
}

.step-title {
  font-size: 26px;
  font-weight: 600;
  color: #0d2841;
  opacity: 0.9;
}

.description {
  margin: 20px 0px 50px 0px;
  font-size: 17px;
  font-weight: 400;
  color: #000000;
}

// Arrow buttons
.arrow-button {
  width: 100px;
  height: 40px;
  border: 3px solid #024b77;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}

// Step 1
.type-container {
  justify-content: center;
  gap: 50px;
}

.ticket-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 200px;
  border: 1px solid #e0dfdf;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 500;
  font-size: 24px;
  color: #024b77;
  gap: 24px;
  cursor: pointer;
}

// Step 2 generic
.generic-type {
  border-bottom: 1px solid #0aaeef;
  padding: 16px 24px;
  cursor: pointer;
}

.generic-type-title {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.generic-type-description {
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}

.generic-2-arrow-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 70px;
}

// Step 2 on product
.onProduct-2-arrow-container {
  display: flex;
  justify-content: space-between;
  margin-top: 270px;
}

.select-container {
  padding: 0px 50px;
}

// Step 3
.step-3-arrow-container {
  display: flex;
  justify-content: space-between;
}

.client-details-container {
  margin: 20px 0px;
}

.ticket-field-label {
  font-weight: 400;
  font-size: 19px;
  color: #666666;
  justify-content: space-between;
  padding-right: 48px;
}

.ticket-field {
  width: 100%;
  background-color: #ffffff !important;
  border-color: #929596;
  height: 30px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 5px;
  border-width: 1.5px;
  outline: 0;
}

// Step 4
.step-4-arrow-container {
  display: flex;
  justify-content: space-between;
  margin-top: 180px;
}

// Ticket create step
.step-number-container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  border: 2px solid #0aaeef;
  border-radius: 50%;
}

// Ticket create cancel button
.ticket-buttons-container {
  display: flex;
  gap: 30px;
}

.ticket-buttons-container button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: none;
  width: 100px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .step-4-arrow-container {
    gap: 10px;
  }

  .ticket-buttons-container {
    gap: 10px;
  }

  .step-container {
    border-bottom: 2px solid #e0dfdf;
    border-right: none;
  }
}
