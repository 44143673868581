/**
 * File: layout.scss
 * Description: This file contains the css of the page layout.
 * Author: Sulakshanee Theja
 * Created: 14/3/2024
 * Last Modified: 14/3/2024 Sulakshanee Theja
 */

.main-container {
  background-color: #f6f6f6;
  min-height: 100vh;
}

.main-sidebar-grid {
  z-index: 2;
}

.main-content-grid {
  z-index: 1;
}
