/**
 * File: updates.scss
 * Description: This file contains the css of the products' update page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 19/12/2023 Sulakshanee Theja
 */


.updates-main-container{
    background-color: #fff;
    border-radius: 5px;
}

.updates-notes-main-container{
    min-height: 65vh;
}

#notes-container{
    border: 1px solid #E0DFDF;
    border-radius: 5px;
}

#notes-version{
    font-weight: 500;
    color: #0D2841;
}

#notes-updated-text{
    font-size: 18px;
    color: #666666;
    opacity: 0.7;
    margin-left: 10px;
}

#notes-desc{
    color: #666666;
}

.historycomponent-tr-heading{
    font-size: 16px;
    font-weight: 400;
    color: #343a40;
}

.historycomponent-tr-data{
    height: 20px;
    color: #8893A0;
}