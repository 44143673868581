/**
 * File: dashboard.scss
 * Description: This file contains the css of the Dashboard page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 27/6/2024 Sulakshanee Theja
 */

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');

body {
  font-family: 'Barlow', sans-serif;
}

.dashboard-head {
  background: #e0dfdf;
  height: 22vh;
  padding: 24px;
}

.dashboard-body {
  margin-top: -100px;
}

.dashboard-welcome-text {
  color: #505661;
  font-size: 20px;
}

.dashboard-sub-component-title {
  color: #024b77;
  font-size: 18px;
  font-weight: 400;
}

/* Overview Component */
.overview-container {
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 28vh;
  padding: 16px;
}

.overview-item-container {
  text-align: center;
  margin-top: 10px !important;
  text-decoration: none;
}

.overview-item-detail-container {
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  text-align: center;
  justify-content: center;
}

.overview-item-number {
  font-size: 38px;
  color: #003566;
  margin: 0;
  margin-right: 5px;
  padding: 0;
  line-height: 0.9;
}

.overview-item-text-container {
  text-align: left;
  color: #003566;
}

.overview-detail-main-text {
  line-height: 1.7;
  font-weight: 600;
  font-size: 12px;
}

.overview-detail-sub-text {
  line-height: 0.7;
  color: #8893a0;
  font-size: 12px;
}

// Subscriptions component
.subscription-container {
  border-radius: 10px !important;
  padding: 16px;
  background-color: #ffffff;
  margin-top: 18px;
}

.subscription-table-container {
  padding: 0px 20px;
}

// Licences component
.license-container {
  border-radius: 10px !important;
  padding: 16px;
  background-color: #ffffff;
}

.carousel-inner-container {
  height: 16vh;
}

.carousel-item {
  height: 20vh;
}

.carousel-caption h5 {
  font-size: 50px;
}

.carousel-caption p {
  font-size: 15px;
}

// Announcements
.horizontal-line {
  margin-bottom: 0;
}

.announcement-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.announcement-title {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: #343a40;
  font-size: 14px;
}

.announcement-date {
  line-height: 0.5;
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #8893a0;
}

.no-announcement-container {
  font-size: 16px;
  padding: 48px;
  color: #6d6875;
}

@media screen and (max-width: 767px) {
  .dashboard-body {
    margin-top: -100px;
  }

  .subscription-container {
    margin-bottom: 18px;
  }

  .overview-item-container {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 25px !important;
  }

  .carousel-caption h5 {
    font-size: 40px;
  }

  .carousel-caption p {
    font-size: 13px;
  }

  .carousel-item {
    height: 24vh;
  }
}
