/**
 * File: topBar.scss
 * Description: This file contains the css of the TopBar modal.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 19/12/2023 Sulakshanee Theja
 */

.top-bar-container{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
    height: 90px;
}

.top-bar-text{
  font-weight: 600;
  margin-left: 5px;
  color: #0D2841;
}

.top-bar-icon-container{
    display: flex;
    gap: 20px;
    margin-right: 20px;
    color: #0D2841;
}

.top-bar-profile-link{
  text-decoration: none !important;
  color: #0D2841;
}

.top-bar-profile-link :hover{
    color: #0D2841;
}