/**
 * File: payments.scss
 * Description: This file contains css of payment related pages.
 * Author: Sulakshanee Theja
 * Created: 27/3/2024
 * Last Modified: 4/7/2024 Sulakshanee Theja
 */

// Payments confirmation
.payment-confirmation-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payment-response-head {
  font-size: 72px;
  font-weight: 600;
  line-height: 144px;
}

.payment-response-body {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #07253F;
  text-align: center;
}

.portal-redirect-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  margin-top: 30px;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 10px 30px;
  font-size: 16px;
}

.portal-redirect-button:hover {
  color: #FFFFFF;
}

.payment-paraqum-logo {
  width: 150px;
  margin-bottom: 50px;
}

.linear-progress-bar {
  height: 10px !important;
  border-radius: 10px;
}

.loading-head {
  font-size: 36px;
  font-weight: 600;
  line-height: 120px;
  color: #406AB3;
}