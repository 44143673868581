/**
 * File: style.scss
 * Description: This file contains global css values.
 * Author: Sulakshanee Theja
 * Created: 20/2/2023
 * Last Modified: 14/3/2024 Sulakshanee Theja
 */

$sidebar-bg-color: #0d2841;
$sidebar-width: 16.7%;
$sidebar-color: #fff;
$highlight-color: #0aaeef;
$icon-bg-color: #0d2841;
$submenu-bg-color: #024b77;
$submenu-bg-color-collapsed: #024b77;
$sidebar-collapsed-width: 100%;
$breakpoint-md: 767px;

@media screen and (max-width: 767px) {
  $sidebar-width: 16.7%;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Barlow', sans-serif !important;
}

@import '~bootstrap/scss/bootstrap';

@media (min-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100% !important;
  }
}

.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  color: #0d2841;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

@media (max-width: $breakpoint-md) {
  .btn-toggle {
    display: flex;
  }
  $sidebar-width: 80%;
}

.toggleIcon {
  width: 38px;
  height: 38px;
}

#main-row {
  min-height: 100vh;
}

#title {
  color: #024b77;
  font-size: 18px;
  font-weight: 400;
}

// Main container of page
.main-body-container {
  padding: 24px !important;
}
