/**
 * File: userProfile.scss
 * Description: This file contains the css of the Profile page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 23/7/2024 Sulakshanee Theja
 */

.user-profile-head {
  background: #e0dfdf;
  height: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 30px 40px;
}

.change-password-text {
  color: #0000ff;
  cursor: pointer;
}

.change-password-text:hover {
  color: rgb(27, 15, 192);
}

.user-profile-body {
  margin-top: -100px;
  padding: 0px 40px;
}

// Client details
.user-details-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}

.user-profile-icon {
  height: 100px;
  width: 100px;
  color: #595260;
}

.user-name {
  font-weight: 500;
  font-size: 20px;
}

.user-company {
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  margin-top: -15px;
}

.user-details-with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.user-details {
  font-size: 16px;
  font-weight: 400;
}

// Contact details & support details card
.details-card-container {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 50px;
  height: 480px;
  overflow: auto;
}

.details-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.details-card-header-text {
  color: #024b77;
  font-weight: 500;
  font-size: 18px;
}

.details-card-header-icon {
  width: 30px;
  height: 30px;
  background-color: rgba(196, 196, 196, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.details-table-icon {
  cursor: pointer;
}

// Profile modals
.profile-modal-container {
  margin-top: 5%;
  width: fit-content;
}

.profile-modal-header {
  font-weight: bold;
  font-size: 20px;
}

.profile-modal-body {
  margin: 0px 50px;
}

.profile-modal-field-container {
  margin-bottom: 15px;
  display: flex;
}

.profile-modal-label {
  font-weight: 500;
  font-size: 18px;
  color: #666666;
}

.profile-modal-field {
  border-radius: 5px;
  border: 1px solid #666666;
  height: 40px;
  outline: none;
  padding-inline: 5px;
  width: 100%;
}

.profile-modal-footer {
  gap: 20px;
}

.profile-modal-footer button {
  border-radius: 10px;
  border: none;
  width: 100px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
}

// Support Details
.support-container {
  margin-bottom: 15px;
}

.support-container-label {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: space-between;
}

.support-container-detail {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  .user-profile-icon {
    margin-bottom: 20px;
  }

  .support-container-label {
    justify-content: unset;
    gap: 20px;
  }

  .support-container-detail {
    margin-left: 50px;
  }
}
