/**
 * File: clientModal.scss
 * Description: This file contains the css of the client's modal component.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 28/3/2024 Sulakshanee Theja
 */

.client-modal-main {
  margin-top: 10%;
}

.client-modal-header {
  font-weight: bold;
  font-size: 20px;
}

.client-modal-field-container {
  margin-bottom: 10px;
}

.client-modal-field-label {
  font-weight: 400;
  font-size: 18px;
  color: #666666;
}

.client-modal-field {
  border-radius: 5px;
  border: 1px solid #666666;
  height: 40px;
  outline: none;
  padding: 5px;
  width: 100%;
}

.client-modal-footer {
  border-top: none;
  padding-bottom: 20px;
  gap: 20px;
  padding-top: 0px;
}

.client-modal-footer button {
  border-radius: 10px;
  border: none;
  width: 100px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
}
