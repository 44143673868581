/**
 * File: errorOccurred.scss
 * Description: This file contains the css of the error pages.
 * Author: Sulakshanee Theja
 * Created: 20/3/2024
 * Last Modified: 20/3/2024 Sulakshanee Theja
 */

.error-page-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-page-sub-container {
  background-color: brown;
  border-radius: 10px !important;
  padding: 50px;
  background-color: #ffffff;
}

.error-page-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.error-page-button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.error-page-button {
  width: 100px;
  height: 30px;
  background: #0472cc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: none;
  color: white;
}