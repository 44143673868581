/**
 * File: auth.scss
 * Description: This file contains the css of the auth pages.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 16/8/2024 Sulakshanee Theja
 */

.auth-main-container {
  min-height: 100vh;
}

// Logo container
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.logo-image {
  width: 300px;
}

// Login form container
.auth-form-container {
  background-color: #0d2841;
  color: white;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form-sub-container {
  width: 300px;
}

.header-text {
  font-weight: 500;
  font-size: 42px;
}

.auth-form {
  margin-top: 25px;
}

.auth-form-field-container {
  margin-top: 20px;
}

.auth-form-label-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-form-label {
  font-weight: 400;
  font-size: 20px;
  color: white;
}

.auth-form-field {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: white;
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  justify-content: space-between;
}

.auth-field-input {
  width: 90%;
  font-weight: 400;
  font-size: 18px;
  border: none;
  outline: none;
}

.auth-field-error {
  color: #d00000;
}

.fogot-password {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  float: right;
}

.auth-form-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.auth-form-button {
  margin-top: 30px;
  width: 100%;
  height: 40px;
  background: #0472cc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  font-weight: 500;
  border: none;
  font-size: 24px;
  color: white;
  letter-spacing: 1px;
}

.auth-form-button:hover {
  background-color: #024b77;
}

.redirect-container {
  margin-top: 20px;
  text-align: center;
}

.redirect-container button {
  width: 150px;
}

.redirect-text {
  font-weight: 400;
  font-size: 17px;
  color: #0472cc;
  cursor: pointer;
}

// Client registration
.registration-container {
  padding: 50px;
}

.register-button {
  width: 250px;
}

// Forgot password
.check-email-container {
  margin: 0px 100px;
}

.check-email-text {
  font-size: 20px;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .logo-image {
    width: 140px;
  }

  .auth-form-sub-container {
    width: 270px;
  }

  .header-text {
    font-size: 36px;
  }

  // Client registration
  .registration-container {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .logo-image {
    width: 180px;
  }

  .header-text {
    text-align: center;
    font-size: 42px;
  }

  .auth-form {
    margin-top: 20px;
  }

  .auth-form-label {
    font-size: 18px;
  }

  .auth-form-button {
    font-size: 23px;
  }

  // Client registration
  .registration-container {
    padding: 50px;
  }
}
