/**
 * File: ticketComments.scss
 * Description: This file contains the css of the comment section of the ticket page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 24/7/2024 Sulakshanee Theja
 */

.ticket_comments-container {
  margin: 24px;
}

.ticket_comment {
  border: 2px solid #e2e3e3 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  margin-bottom: 24px;
  padding: 16px;
}

.comment-head-container {
  color: #666666;
}

.comment-title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.comment-logo {
  width: 20px;
}

.comment-title {
  font-size: 16px !important;
}

.comment-date-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.comment-date {
  font-size: 16px !important;
}

.comment-email-span {
  margin-left: 10px;
  color: #666666;
}

.comment-body-container {
  margin: 15px 0px 0px 30px;
}

.comment-description-span {
  cursor: pointer;
  color: #0472cc;
}

.comment-attachments {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .comment-date-container {
    justify-content: start;
    margin-top: 5px;
  }
}
