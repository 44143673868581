/**
 * File: tickets.scss
 * Description: This file contains the css of the Ticket page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 24/7/2024 Sulakshanee Theja
 */

// Create ticket button
.new-ticket-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  background-color: #0472cc;
  max-width: 200px;
  color: #ffffff;
  border-radius: 5px;
  font-size: 17px;
  text-decoration: none !important;
  margin-bottom: 20px;
}

.new-ticket-button:hover {
  color: #ffffff;
}

.tickets-container {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 85vh;
}

// Ticket list
.ticket-list {
  height: 85vh;
  overflow-y: scroll;
  border-right: 1px solid #dfe3e6;
}

.created-tickets-container {
  background-color: #6c757d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 0px 0px 0px;
}

.created-tickets-container-title {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}

.created-tickets-container-pagecount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}

.ticket-list-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfe3e6;
  gap: 8px;
  padding: 16px 8px;
}

.ticket-list-container:hover {
  cursor: pointer;
}

.ticket-title {
  color: #3d405b;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
}

.ticket-subject-details {
  display: flex;
  gap: 8px;
  color: #666666;
  font-size: 12px;
  opacity: 0.8;
}

// Ticket 'NO Data' container
.ticket-no-data-container {
  text-align: center;
  align-self: center;
  font-size: 30px;
  color: #6c757d;
  font-weight: bolder;
  letter-spacing: 2px;
}

// Ticket loading container
.ticket-loading-container {
  text-align: center;
  align-self: center;
}

// Ticket details
.ticket-details-container {
  height: 85vh;
  overflow-y: scroll;
  background-color: #ebebeb;
}

.ticket-details-sub-container {
  margin: 16px;
  background-color: #ffffff;
  border-radius: 5px;
}

.ticket-details-title-container {
  border-bottom: 1px solid #dfe3e6;
  padding: 24px;
}

.ticket-details-title {
  font-size: 20px;
}

.ticket-details-priority {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.ticket-details-description {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 400;
  color: #000000;
}

// Add comment or ticket reopen button
.button-container {
  display: flex;
  justify-content: flex-end;
}

.add_comment_or_reopen-button,
.add_comment_or_reopen-button:hover {
  height: 36px;
  text-align: center;
  padding: 5px;
  width: 150px;
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none !important;
}

.ticket-details-body-container {
  margin: 24px 40px;
  padding-bottom: 24px;
}

.ticket-detail-item {
  margin-bottom: 16px;
  vertical-align: middle;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label-sub-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ticket-details-Label {
  font-size: 15px;
  font-weight: 500;
  color: #666666;
}

.ticket-details {
  font-size: 16px;
  font-weight: 500;
}

// Ticket status
.ticket-status-item {
  display: flex;
  gap: 40px;
}

.ticket-status-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
}

.ticket-status-line {
  background-color: #0d2841;
  width: 1px;
  height: 50px;
  margin: 8px 0px;
}

.ticket-status-step {
  font-size: 17px;
  font-weight: 600;
  color: #000000;
}

.ticket-status-step-date {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}

@media screen and (max-width: 767px) {
  .ticket-details-container {
    height: auto;
    border-top: 2px solid #e0dfdf;
  }

  .ticket-list {
    height: 40vh;
  }

  .label-container {
    justify-content: unset;
    gap: 20px;
  }

  .ticket-details {
    margin-left: 50px;
  }

  .button-container {
    justify-content: start;
    margin-top: 20px;
  }
}
