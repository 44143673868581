/**
 * File: billingStyles.scss
 * Description: This file contains the css of the billing page.
 * Author: Nethmi Aththanayaka
 * Created: 22/8/2023
 * Last Modified: 30/7/2024 Sulakshanee Theja
 */

// Navigation tabs
.nav-pills .pill .nav-link {
  background-color: #0d2841;
  color: #ffffff;
  border-radius: 1px;
  margin-bottom: 20px;
}

.nav-pills .pill .nav-link:not(.active) {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-left: 1px solid #ccd3d9;
}

.nav-pills .pill .nav-link:hover {
  transform: scale(1.02);
}

// Selected device bill details
.device-bill-container {
  background-color: #ffffff;
  border-radius: 5px;
  height: 350px;
  overflow: auto;
  padding: 20px;
}

.bill-details-label {
  font-weight: 400;
  font-size: 15px;
  color: #666666;
}

.bill-details-value {
  font-weight: 400;
  font-size: 17px;
  color: #0d2841;
  margin-bottom: 20px;
}

.curent-bill-value-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.current-bill-icon {
  color: #0472cc;
  margin-bottom: 20px;
  cursor: pointer;
}

.current-bill-button-link {
  text-decoration: none;
}

.current-bill-button {
  min-height: 35px;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  width: 90%;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  text-align: center;
}

.pay-now-button {
  background-color: #0472cc;
}

.download-button {
  background-color: #33b249;
}

.not-approved-button {
  background-color: #ffa500;
}

.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  font-size: 18px;
  color: #6c757d;
  font-weight: bolder;
  letter-spacing: 2px;
  background-color: #ffffff;
  gap: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  background-color: #ffffff;
}

// Recent invoices
.recent-bills-heading {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

.recent-bills-table-head {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: rgba(102, 102, 102, 0.75);
}

.recent-bills-table-body {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.recent-bills-status-label {
  display: flex;
  justify-content: center;
  width: 80px !important;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}

.billing-table-data-download {
  font-weight: 400;
  font-size: 14px;
  color: #0472cc;
}

.recent-bills-table-loading {
  height: 230px;
  vertical-align: middle;
  text-align: center;
  background-color: #262b2f;
}

// Subscriber count graph
.subscriber-count-graph-container {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  min-height: 350px;
}

.filter-sub-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.filter-select {
  width: 180px !important;
}

.subscriber-count-graph-title {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

// Current bill modal
.current-bill-modal {
  margin-top: 10%;
}

.current-bill-modal-header {
  font-weight: bold;
  font-family: 'Roboto';
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.current-bill-body-detail {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(102, 102, 102, 0.75);
}

.current-bill-body-amount {
  font-weight: 400;
  font-size: 16px;
}

.current-bill-body-total {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.75);
}

.current-bill-close-button {
  width: 100px;
  height: 35px;
  font-weight: 500px;
  font-size: 18px;
  background-color: #b3b9bf;
  border-radius: 5px;
  color: #0d2841;
  border: none;
  text-align: center;
}

// Billing history
.billinghistory-container {
  background-color: #fff;
  border-radius: 5px;
  min-height: 350px;
  margin-top: 1rem;
  padding: 1rem 1rem;
}

@media screen and (max-width: 990px) {
  .device-bill-container {
    margin-top: 20px;
  }

  .download-button {
    margin-top: 10px;
  }

  .current-bill-button {
    width: 160px;
  }
}

@media screen and (max-width: 767px) {
  .filter-sub-container {
    justify-content: space-between;
    gap: 0px;
    margin-bottom: 10px;
  }
}
