/**
 * File: sidebar.scss
 * Description: This file contains the css of the SideBar modal.
 * Author: Sulakshanee Theja
 * Created: 13/3/2024
 * Last Modified: 14/3/2024 Sulakshanee Theja
 */

// Sidebar logo
.sidebar-logo {
  width: 100%;
  max-width: 200px;
}
