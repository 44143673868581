/**
 * File: notifications.scss
 * Description: This file contains the css of the notification part in TopBar page.
 * Author: Sulakshanee Theja
 * Created: 22/8/2023
 * Last Modified: 19/12/2023 Sulakshanee Theja
 */

.top-bar-notification {
    .dropdown-toggle::after {
        display: none !important;
    }

    .dropdown-menu {
        width: 350px !important;
        height: 480px !important;
        overflow-y: auto !important;
        padding: 5px 15px !important;
        background-color: #f6f6f6 !important;
        margin-top: 10px !important;
    }
}
  
.notification-badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #d32f2f;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: -10px 0px 0px 18px;
    font-size: 12px;
    font-weight: 900;
}

.notification-header {
    font-size: 24px !important;
    font-weight: bolder !important;
}

.notification-reader {
    display: flex !important;
    align-items: center !important;
    font-size: 16px !important;
    color: #4599ff !important;
    gap: 5px !important;
    cursor: pointer !important;
    margin-top: 10px !important;
}

.notification-card {
    margin: 16px 0px 0px 0px !important;
    padding: 8px 0px !important;
    border-radius: 5px !important;
    box-shadow: 0.4px 0.4px 1px #7498b7 !important;
    cursor: pointer !important;
}

.notification-medium-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.notification-logo {
    width: 20px !important;
}

.notification-title-container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-right: 8px !important;
}

.notification-title {
    font-weight: 600 !important;
}

.notification-time {
    font-weight: 400 !important;
    color: #1877f2 !important;
    font-size: 12px !important;
}
